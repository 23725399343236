import React from 'react';
import ReactDOM from "react-dom/client";

import './index.css';
import App from './components/App/App';

console.log(process.env.NODE_ENV)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
